body {
	background: #f0f2f5;
}

.has-error .ant-form-explain {
	font-size: 10px;
	position: absolute;
}

.ant-form-item-with-help {
	margin-bottom: 24px;
}

.actions-wrapper {
	display: flex;
	align-items: center;
}
.actions-wrapper button {
	margin: 0 6px;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr > span > span {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
}
